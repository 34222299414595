/**
 * Template - Basic Landing with no banner
 *
 * Usage
 * - http://localhost:8000/
 */
import { graphql } from 'gatsby'
import React, { useContext } from 'react'
import { SFHero, SFTopNav } from 'suf_storybook'
import { useLocation } from '@reach/router'
import { Query } from '../generated/gatsby.types'
import { OverlayContext } from '../components/OverlayContext'
import Main from '../components/main'
import removeEmptySections from '../lib/removeEmptySections'
import Sections from '../components/sections'
import SEO from '../components/seo'
import parseBannerProps from '../lib/parseBannerProps'

type Props = {
  data: Query
  pageContext: any
}

export default ({ data, pageContext }: Props) => {
  const { hasMarketo } = useContext(OverlayContext)
  const { customContent } = pageContext
  const { contentfulLandingPage } = data
  const { sections, seoTitle, seoDescription, noIndex } = contentfulLandingPage!
  const LandingSections = removeEmptySections(data, sections)

  const typeName = contentfulLandingPage?.__typename || ''

  const landingMarketoFormId = data.contentfulLandingPage?.marketoFormId
  const landingFullLengthMarketoForm =
    data.contentfulLandingPage?.fullLengthForm
  const isEvents = useLocation().pathname === '/events'

  return (
    <Main>
      <SEO
        title={seoTitle || 'Sustainable Fitch'}
        description={
          seoDescription || data.contentfulLandingPage?.banner?.description
        }
        noIndex={noIndex}
        type={typeName}
        keywords={[`sustainable fitch`, seoTitle]}
      />
      {isEvents ? (
        <SFTopNav />
      ) : (
        <SFHero
          {...parseBannerProps(
            data.contentfulLandingPage?.banner,
            landingMarketoFormId,
            landingFullLengthMarketoForm
          )}
          hasMarketo={hasMarketo}
          isActionBarOpen={data.contentfulLandingPage?.isActionBarOpen}
        />
      )}
      <Sections
        data={data}
        sections={LandingSections}
        customContent={customContent}
      />
    </Main>
  )
}

export const query = graphql`
  query ($slug: String!) {
    contentfulLandingPage(slug: { eq: $slug }) {
      __typename
      banner {
        ...ContentfulBannerFragment
      }
      sections {
        __typename
        ... on Node {
          ...ContentfulStaticSectionsFragment
          ...ContentfulDynamicSectionsFragment
        }
      }
      marketoFormId
      fullLengthForm
      seoTitle
      seoDescription
      noIndex
      slug
    }
    allContentfulEvent(
      filter: {
        node_locale: { eq: "en-US" }
        metadata: {
          tags: { elemMatch: { contentful_id: { eq: "sustainableFitch" } } }
        }
      }
      sort: { fields: [startDate], order: ASC }
    ) {
      ...ContentfulEvents
    }
  }
`
